import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/postLayout.js";
import Seo from "../../components/seo";
import VideoPlayer from "../../components/post/video";
import TestComponent from "../../components/post/TestComponent";
import { Space24 } from "../../components/styles/Primitives";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title="Kayak Flights | Atharva Patil" mdxType="Seo" />
    <h1>{`Helping users find the best flights for their trips`}</h1>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1200px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "48.666666666666664%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Kayak cover image",
        "title": "Kayak cover image",
        "src": "/static/458dd69ba02d85b8e5d7f3638d3778c0/c1b63/kayak.png",
        "srcSet": ["/static/458dd69ba02d85b8e5d7f3638d3778c0/5a46d/kayak.png 300w", "/static/458dd69ba02d85b8e5d7f3638d3778c0/0a47e/kayak.png 600w", "/static/458dd69ba02d85b8e5d7f3638d3778c0/c1b63/kayak.png 1200w", "/static/458dd69ba02d85b8e5d7f3638d3778c0/d61c2/kayak.png 1800w", "/static/458dd69ba02d85b8e5d7f3638d3778c0/efb68/kayak.png 2032w"],
        "sizes": "(max-width: 1200px) 100vw, 1200px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <div style={{
      "maxWidth": "840px",
      "margin": "0 auto"
    }}>
      <h4>{`Overview`}</h4>
      <p><a parentName="p" {...{
          "href": "https://kayak.com"
        }}>{`Kayak`}</a>{` is an American online travel agency and metasearch engine that helps travellers across the globe plan their travel by reserving flights, cars, hotels, etc. I worked with the flights pod to work on improving search features.`}</p>
      <h5>{`My role`}</h5>
      <p>{`User research, End-to-End design, prototyping `}<b>{`(2019)`}</b></p>
      <p><strong parentName="p">{`Collaborators:`}</strong></p>
      <p>{`Irene Georgiou, Alkistis Mavroeidi(Design), Anthony Salomone, Luciana Bueno(Product)`}</p>
      <h5>{`Problem`}</h5>
      <p>{`Traveling leisurely & comfortably can mean different things for different users. For some it's finding an late evening flight on friday & early morning flight back on monday. Or for a family it may mean lesser layovers & services provided for childcare by the flights.`}</p>
      <p>{`This lead to helping future travellers answer 2 questions:`}</p>
      <h3>{`“Which is the best flight for me?”`}</h3>
      <h3>{`“When is the right time to buy the tickets?”`}</h3>
      <hr></hr>
      <h3>{`Understanding users`}</h3>
      <p>{`To understand how users navigate flight search for different scenarios such as weekend trips, family trips, business trips, etc. I & fellow PM intern Luciana ran user studies.`}</p>
      <figure>{`
  `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "User research session at Kayak",
            "title": "User research session at Kayak",
            "src": "/static/a5d1f841fb2e70802069aaecf2dd85db/c1b63/user-research.png",
            "srcSet": ["/static/a5d1f841fb2e70802069aaecf2dd85db/5a46d/user-research.png 300w", "/static/a5d1f841fb2e70802069aaecf2dd85db/0a47e/user-research.png 600w", "/static/a5d1f841fb2e70802069aaecf2dd85db/c1b63/user-research.png 1200w", "/static/a5d1f841fb2e70802069aaecf2dd85db/52576/user-research.png 1412w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
  `}<figcaption parentName="figure">{`User research session at Kayak.`}</figcaption>
      </figure>
      <p>{`From these user studies we found,`}</p>
      <ul>
        <li parentName="ul">{`Final flight selection was a combination of `}<b>{`airline, layover, layover duration & price`}</b>{` as the most common filters.`}</li>
        <li parentName="ul">{`For weekend trips the priorities shifted to `}<b>{`right departure and arrival times`}</b>{`(leave late on Friday & return early on Monday) & `}<b>{`no layovers.`}</b></li>
        <li parentName="ul">{`For more complicated international trips where layovers and airline changes were not optional users `}<b>{`failed to recognise these constraints.`}</b></li>
      </ul>
      <hr></hr>
      <p>{`First question we helped answer, `}<b>{`“Which is the best flight for me?”`}</b></p>
      <h3>{`1. Travel Hacker`}</h3>
      <p>{`For longer connected flights to slightly less popular airports it’s significantly cheaper to buy tickets with connecting airlines or have airport layover. What does this mean? let’s take a scenario`}</p>
      <h5>{`“A traveller going to San Franciso may save $300 on flight tickets by switching flights between airports in New York. This could mean landing at JFK checking out bags & carrying them on the A-train during rush hour to Laguardia with luggage to get to catch the connecting flight with a different airline within 2 hours.”`}</h5>
      <figure>{`
  `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "24.333333333333332%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAj0lEQVQY06WOwQ4CMQgF9/9/U7Mq0BbazZJNKZitxoMHL3KaMHnJLPHHLcN9jCEix3G4h3tUqbqrT62qABBx/nXX1tpkf4+v9GjblnPZar1CgSwlZxG+Ea/IwowImOUChRApZQZs672bnWNEtEndDCkxS0SYGaUESKoa4UTpVCK9d0oJkepMWF4Bn5Iv/q2e5SojW/C03icAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "User journey for travel hacker persona",
            "title": "User journey for travel hacker persona",
            "src": "/static/657b9f4eb78cc8773fa4565dab21f03f/c1b63/journey.png",
            "srcSet": ["/static/657b9f4eb78cc8773fa4565dab21f03f/5a46d/journey.png 300w", "/static/657b9f4eb78cc8773fa4565dab21f03f/0a47e/journey.png 600w", "/static/657b9f4eb78cc8773fa4565dab21f03f/c1b63/journey.png 1200w", "/static/657b9f4eb78cc8773fa4565dab21f03f/52576/journey.png 1412w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
  `}<figcaption parentName="figure">{`User journey for travel hacker persona.`}</figcaption>
      </figure>
      <p>{`The user can `}<b>{`save significant money`}</b>{` by doing this but it means they have to take the extra effort to change airport with their luggage. This is where context comes to play. A lone traveller may find this as a reasonable offer but `}<b>{`for a family with kids this maybe an impossible option.`}</b></p>
      <h4>{`Design, adding context`}</h4>
      <figure>{`
  `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAG8AD//xAAUEAEAAAAAAAAAAAAAAAAAAAAQ/9oACAEBAAEFAn//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAQ/9oACAEBAAY/An//xAAZEAABBQAAAAAAAAAAAAAAAAARAAEQIUH/2gAIAQEAAT8hsQx1f//aAAwDAQACAAMAAAAQAA//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAZEAACAwEAAAAAAAAAAAAAAAABIQARUUH/2gAIAQEAAT8QLCKJzkZOQmpTTn//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Old vs new flight card design",
            "title": "Old vs new flight card design",
            "src": "/static/4e664f9452ea7d65aafd5019aaaffb2f/e5166/travel-hacker.jpg",
            "srcSet": ["/static/4e664f9452ea7d65aafd5019aaaffb2f/f93b5/travel-hacker.jpg 300w", "/static/4e664f9452ea7d65aafd5019aaaffb2f/b4294/travel-hacker.jpg 600w", "/static/4e664f9452ea7d65aafd5019aaaffb2f/e5166/travel-hacker.jpg 1200w", "/static/4e664f9452ea7d65aafd5019aaaffb2f/d9c39/travel-hacker.jpg 1800w", "/static/4e664f9452ea7d65aafd5019aaaffb2f/df51d/travel-hacker.jpg 2400w", "/static/4e664f9452ea7d65aafd5019aaaffb2f/97894/travel-hacker.jpg 2501w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
  `}<figcaption parentName="figure">{`Old vs new flight card design.`}</figcaption>
      </figure>
      <h2>{`Interactions`}</h2>
      <VideoPlayer linkHash="S4TftpblP8k" mdxType="VideoPlayer"></VideoPlayer>
      <figure>{`
  `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAFABQDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAG8AD//xAAUEAEAAAAAAAAAAAAAAAAAAAAQ/9oACAEBAAEFAn//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAQ/9oACAEBAAY/An//xAAUEAEAAAAAAAAAAAAAAAAAAAAQ/9oACAEBAAE/IX//2gAMAwEAAgADAAAAEAPP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGhABAQACAwAAAAAAAAAAAAAAAREAECFBUf/aAAgBAQABPxCPMZcB7a+6/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Shown vs hidden state of filtered flight cards",
            "title": "Shown vs hidden state of filtered flight cards",
            "src": "/static/2c0ab4a20bd4ced7024cb6a6073e6253/e5166/travel-collapse.jpg",
            "srcSet": ["/static/2c0ab4a20bd4ced7024cb6a6073e6253/f93b5/travel-collapse.jpg 300w", "/static/2c0ab4a20bd4ced7024cb6a6073e6253/b4294/travel-collapse.jpg 600w", "/static/2c0ab4a20bd4ced7024cb6a6073e6253/e5166/travel-collapse.jpg 1200w", "/static/2c0ab4a20bd4ced7024cb6a6073e6253/d9c39/travel-collapse.jpg 1800w", "/static/2c0ab4a20bd4ced7024cb6a6073e6253/df51d/travel-collapse.jpg 2400w", "/static/2c0ab4a20bd4ced7024cb6a6073e6253/f321e/travel-collapse.jpg 2825w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
  `}<figcaption parentName="figure">{`Shown vs hidden state of filtered flight cards.`}</figcaption>
      </figure>
      <figure>{`
  `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Shown vs hidden flight card in website context",
            "title": "Shown vs hidden flight card in website context",
            "src": "/static/579222b4d32ec79415898a8e114b38e0/c1b63/travel-og.png",
            "srcSet": ["/static/579222b4d32ec79415898a8e114b38e0/5a46d/travel-og.png 300w", "/static/579222b4d32ec79415898a8e114b38e0/0a47e/travel-og.png 600w", "/static/579222b4d32ec79415898a8e114b38e0/c1b63/travel-og.png 1200w", "/static/579222b4d32ec79415898a8e114b38e0/d61c2/travel-og.png 1800w", "/static/579222b4d32ec79415898a8e114b38e0/4faaa/travel-og.png 2308w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
  `}<figcaption parentName="figure">{`Shown vs hidden flight card in website context.`}</figcaption>
      </figure>
      <h3>{`2. Flight preference personalization`}</h3>
      <p>{`Even though users had different mental models for searching flights for domestic & international travel one preference persisted. `}<b>{`Airline preference, avoiding certain airlines.`}</b>{` Either due to service or points with other airline partner.`}</p>
      <h2>{`On deselecting airline`}</h2>
      <figure>{`
  `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAAByUlEQVQozyWQSWsUURRG69+40z+g4DIKBhVExGGRgLQLFyqIqBBEBA04IJFGMDHgRlAUtdtxEbJQRAwYQtLQjgnd1VVdXdU1vfeqXg1HqnLhri6c79zPcL0AqVJ0VvDbHNFsfeHp8g/8IMLzQ9xxQBgJdJYjpeLvVh/KjEerPpP3l7nQbHFu7g03XnWIkgLDNG1M04I8p/11nR3Ts+ybWWTo+jUsEhKpEpJUI4Rk1NsE6fNto8fNhffcW3jJ3cUWi2+/I5TGGAUhsVCkOidWKXEsEDIhTTV5XtRbFAVCpbWpkgooWfq8wvnrc1y5/ZjG1Ttcnn1IJBKMfyMH03bwxkH9VhSEDO0hPXNAlmW1VQV0xz6xkHS6v6jmRfsj+49OcfBkg72TxzkydZYgEhhhJNnc6tPrD4ikwrMcLNPCclxUkqBUgtaaMJakkc+43aR4N8/rZ885c3GGQydOs2vPBAeOTROEAsP2Alw/ROclSZohpELnRW2R5TllSd1fdavqiEc2Zezz4dMSD+afcOnaLXbunuDwqca2YUX1/AgZK3SSEgw9HGtIf2CRZTm+H5AqheONiauw7Sxsx2VldZ21zk/WNrp0un/q0P8d9e3Iu89DvgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Account setting option to hide airline permanently",
            "title": "Account setting option to hide airline permanently",
            "src": "/static/6114e269db25db9dfe8f35c01788af0b/c1b63/preference2.png",
            "srcSet": ["/static/6114e269db25db9dfe8f35c01788af0b/5a46d/preference2.png 300w", "/static/6114e269db25db9dfe8f35c01788af0b/0a47e/preference2.png 600w", "/static/6114e269db25db9dfe8f35c01788af0b/c1b63/preference2.png 1200w", "/static/6114e269db25db9dfe8f35c01788af0b/985a9/preference2.png 1512w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
  `}<figcaption parentName="figure">{`Account setting option to hide airline permanently.`}</figcaption>
      </figure>
      <h2>{`Interaction`}</h2>
      <VideoPlayer linkHash="FN6uFE79ex8" mdxType="VideoPlayer"></VideoPlayer>
      <h2>{`On filtering out results`}</h2>
      <figure>{`
  `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Account setting option to hide airline permanently",
            "title": "Account setting option to hide airline permanently",
            "src": "/static/4588055409f6c94dab81678cca80c33a/c1b63/preference1.png",
            "srcSet": ["/static/4588055409f6c94dab81678cca80c33a/5a46d/preference1.png 300w", "/static/4588055409f6c94dab81678cca80c33a/0a47e/preference1.png 600w", "/static/4588055409f6c94dab81678cca80c33a/c1b63/preference1.png 1200w", "/static/4588055409f6c94dab81678cca80c33a/985a9/preference1.png 1512w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
  `}<figcaption parentName="figure">{`Account setting option to hide airline permanently.`}</figcaption>
      </figure>
      <p>{` `}<span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Kayak cover image",
            "title": "Kayak cover image",
            "src": "/static/4588055409f6c94dab81678cca80c33a/c1b63/preference1.png",
            "srcSet": ["/static/4588055409f6c94dab81678cca80c33a/5a46d/preference1.png 300w", "/static/4588055409f6c94dab81678cca80c33a/0a47e/preference1.png 600w", "/static/4588055409f6c94dab81678cca80c33a/c1b63/preference1.png 1200w", "/static/4588055409f6c94dab81678cca80c33a/985a9/preference1.png 1512w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <hr></hr>
      <p>{`Second question we helped answer, `}<b>{`“When is the right time to buy the tickets?”`}</b></p>
      <p>{`Flights are a major chunk of expense during a trip. Getting the cheapest & best deals is a huge motivation and the reason why services such as Kayak exist. Flight prices are in a constant flux with variable demand, availability, etc contributing to the price changes.`}</p>
      <p>{`To address this the flight price predictor was built.`}</p>
      <h3>{`3. Flight price predictor`}</h3>
      <figure>{`
  `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3VBR/8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQABBQJf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAFxAAAwEAAAAAAAAAAAAAAAAAABARIf/aAAgBAQABPyEmKP8A/9oADAMBAAIAAwAAABDzD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABsQAQACAgMAAAAAAAAAAAAAAAEAESExEGFx/9oACAEBAAE/EA2+xVAabuGsyme4FHH/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Account setting option to hide airline permanently",
            "title": "Account setting option to hide airline permanently",
            "src": "/static/861c1609164a80531b9ade7ff74c8932/e5166/price1.jpg",
            "srcSet": ["/static/861c1609164a80531b9ade7ff74c8932/f93b5/price1.jpg 300w", "/static/861c1609164a80531b9ade7ff74c8932/b4294/price1.jpg 600w", "/static/861c1609164a80531b9ade7ff74c8932/e5166/price1.jpg 1200w", "/static/861c1609164a80531b9ade7ff74c8932/d9c39/price1.jpg 1800w", "/static/861c1609164a80531b9ade7ff74c8932/121b3/price1.jpg 1988w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
  `}<figcaption parentName="figure">{`Account setting option to hide airline permanently.`}</figcaption>
      </figure>
      <p>{`On the flights desktop results page(top left) lives recommendation. It tells the users to “buy” or “wait” to buy tickets based on prices predicted till the departure date.`}</p>
      <figure>{`
  `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACUElEQVQoz22RvW8VRxTF319ElR7RpQkSDQ0NKZIGAQaBTEiTBkVURojvAgHPokCi4w9AQhQgZIgbSwEZ8/Fsv933Zndn53tm94dmMEWk3GZGd+ace865k3F6mvBiSnqxTphewD04z7C+Slr/g/joEml6mXD/Iv7OGcLdc7hbK7ibK9gb5zDXV9DXzqJvrqJv/4laO89kBEQjaaVCJ5BhpLEJlcACC+XQ4/f70gQcYKCc/1eT6By9lATnicZilUZrTfAeZw1Wa1IMGGOwxhBDwDtH8IEQB740isYGYhrK28RbR9U2NH2P1gYle3qty7S5aNgTLdoHWtkTYiTEBEPg5Yea4/decfLhBicfveXUk016mwmdR4imAFJKOOvppKaua5ZNi/UebSzOe6x1fN2bk1Lk+bt/+eXiGkd+XeXQ0d85dvZvlPVZoUcsRFFmlSkgsVexO9tlKRpCCLgci9IobZjvzjCiYuPdJtPHT/jrylV+OvwzJ347jTaOiQ+ReiEKwIVIby0uBMZxZBhGUhoYhgHnA12vcUYzeltcvf5nizebW2x92GH784zMNcmZzOY1u/tVUdAZS10L6ipb/qHQY6xDKs3Op0/UVVUynu1VzGf7+DSgrSfGdKBwKUpeGWh9YJk/7le0UhJjLP28MOsj3etndE+vY3feY7xF9wplHFKbsrBCuF/VLERTyDpl6LUhDUNRke3m7dqsMhN3DV42mF5S95HOpLK0jCuEOZu2k8V/bijtsFJRLZaIpv2PZdHKEk0emGu5+IhYbmNcQh4QfgPtDC6BEL5kTQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Account setting option to hide airline permanently",
            "title": "Account setting option to hide airline permanently",
            "src": "/static/3521604ddf917a82c0d513b631e922a8/c1b63/price2.png",
            "srcSet": ["/static/3521604ddf917a82c0d513b631e922a8/5a46d/price2.png 300w", "/static/3521604ddf917a82c0d513b631e922a8/0a47e/price2.png 600w", "/static/3521604ddf917a82c0d513b631e922a8/c1b63/price2.png 1200w", "/static/3521604ddf917a82c0d513b631e922a8/07a9c/price2.png 1440w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
  `}<figcaption parentName="figure">{`Account setting option to hide airline permanently.`}</figcaption>
      </figure>
      <p>{`To add authenticity & give transparency to user why these predictions are being made. A visual indicator was added in the form of a price graph.`}</p>
      <figure>{`
  `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAGABQDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAG9PAD/xAAYEAADAQEAAAAAAAAAAAAAAAAAAQIDIf/aAAgBAQABBQIvmsJn/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGRAAAwADAAAAAAAAAAAAAAAAAAERAnGB/9oACAEBAAY/AjKq9Hs//8QAGBABAQEBAQAAAAAAAAAAAAAAAREAITH/2gAIAQEAAT8hmAidk8Td04NBfN//2gAMAwEAAgADAAAAEHPP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAFxABAQEBAAAAAAAAAAAAAAAAAREhAP/aAAgBAQABPxBE4ARp1gbh0jK9diGd/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Account setting option to hide airline permanently",
            "title": "Account setting option to hide airline permanently",
            "src": "/static/3f6d7dd9dfe12c35a5f652fe986d15c2/e5166/price3.jpg",
            "srcSet": ["/static/3f6d7dd9dfe12c35a5f652fe986d15c2/f93b5/price3.jpg 300w", "/static/3f6d7dd9dfe12c35a5f652fe986d15c2/b4294/price3.jpg 600w", "/static/3f6d7dd9dfe12c35a5f652fe986d15c2/e5166/price3.jpg 1200w", "/static/3f6d7dd9dfe12c35a5f652fe986d15c2/d9c39/price3.jpg 1800w", "/static/3f6d7dd9dfe12c35a5f652fe986d15c2/df51d/price3.jpg 2400w", "/static/3f6d7dd9dfe12c35a5f652fe986d15c2/d280f/price3.jpg 3141w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
  `}<figcaption parentName="figure">{`Account setting option to hide airline permanently.`}</figcaption>
      </figure>
      <figure>{`
  `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACUUlEQVQoz2WSW08TURSF+z+Mbz4oiS9qQtBWBeUWBUKvc+nMdNrB1iKY+rcUWsALCoFa8Gdg4yVGqBToxV6GzmfOoWmMTrKy9lmTrLP23sdnTIwQn5u+wMQdzKkAiel7WAJTfZ68izXux5oIYI77MccDmA8F/Gj3b6ON+dHHArL2GU4GxbBRzSRqwmEmrPAoGJU8G1FlPRvRmItqPA4pkoUuOKgamKk0lpPBdNKYTgZfPJFCs5ISTjrLSn6N12/eUVjfoLC2wWphjZV8gVereV6u5OU5X1iX/4UmAsUMG8NeQHj5dCuFYtpENIPF5Rw/D4+onpzy7+e6Lr1e7z99NPaEa6NBbkwraGZKJHRQDZuoZvDs+QsOPpf5+u07R5UKx9UqrXYbz/OoHFep1ergefRcF/Co1xsMP3jMpas3GRoevTAUCVXTJqZbZJdzHB5V6HneIEGn28XtdhFK57zH7+45v1pdam6Pw1qTyZl5Ll8Z4vqtETQz2W85npCGT5dyMl2r1aLT6cg2BZ+2OvyoNSmfNSnXOxzU2pSbLl9OG1ipNDOhGGHVQHjJlqO6RTCqk13KcdZsUeu4eH/NSdQnPWgA7T66QMP1yGSXUYwEeiKF8BokjGomTmaRze0dtoolinv7lPY/SewWP1Is7VEs7bOzvc3Oh02Ku0Xevt8ilV6UhuKVDAxFQmEqZhCK6YQUnYhqyEWJ7Ys6rMQlQlGVsEBMY14xUMwUupWU73hgKLYsWAgCRl8TF5jJBQzbIW47MoW4WLQnZqcacdS4jm45A8M/i3JJqaeQZdsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Account setting option to hide airline permanently",
            "title": "Account setting option to hide airline permanently",
            "src": "/static/93c8a14fa298ea90e696c2f278eaf24f/c1b63/price4.png",
            "srcSet": ["/static/93c8a14fa298ea90e696c2f278eaf24f/5a46d/price4.png 300w", "/static/93c8a14fa298ea90e696c2f278eaf24f/0a47e/price4.png 600w", "/static/93c8a14fa298ea90e696c2f278eaf24f/c1b63/price4.png 1200w", "/static/93c8a14fa298ea90e696c2f278eaf24f/07a9c/price4.png 1440w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
  `}<figcaption parentName="figure">{`Account setting option to hide airline permanently.`}</figcaption>
      </figure>
      <p>{`As travel plans are made rarely people are unaware of what the average price of flights to the destinations they are travelling to. The next version of the graph included colors & price ranges to help users identify if they are paying more or less than average prices. Giving them additional information to take a decision.`}</p>
      <figure>{`
  `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEDBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABpt47SYw//8QAGxAAAgEFAAAAAAAAAAAAAAAAAAEDAgQQEhT/2gAIAQEAAQUCt4qZDmiEJtGzx//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABoQAAICAwAAAAAAAAAAAAAAAAABITEQEpH/2gAIAQEABj8CexTJRDLfcf/EABsQAAICAwEAAAAAAAAAAAAAAAABITEQEXHB/9oACAEBAAE/IYuoer0JKBFgLjyz/9oADAMBAAIAAwAAABCc/wD/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPxCn/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Qh//EABoQAQEBAAMBAAAAAAAAAAAAAAERACExUYH/2gAIAQEAAT8QMatgRndwk8094qUL1NQ5Puhc/H9Y3//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Account setting option to hide airline permanently",
            "title": "Account setting option to hide airline permanently",
            "src": "/static/eba2bb7a89871f157243cb97e93cfee0/e5166/price5.jpg",
            "srcSet": ["/static/eba2bb7a89871f157243cb97e93cfee0/f93b5/price5.jpg 300w", "/static/eba2bb7a89871f157243cb97e93cfee0/b4294/price5.jpg 600w", "/static/eba2bb7a89871f157243cb97e93cfee0/e5166/price5.jpg 1200w", "/static/eba2bb7a89871f157243cb97e93cfee0/d9c39/price5.jpg 1800w", "/static/eba2bb7a89871f157243cb97e93cfee0/274e1/price5.jpg 2160w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
  `}<figcaption parentName="figure">{`Account setting option to hide airline permanently.`}</figcaption>
      </figure>
      <p>{`The aim of the feature was to drive users to get email alerts of notifications on their app when the price drops or is at the minimum.`}</p>
      <hr></hr>
      <h2>{`Smaller contributions`}</h2>
      <p>{`I hosted a prototyping workshop while at Kayak where I talked about the aspect of creating more high fidelity prototypes by mixing designs with code & understanding how design systems live as code.`}</p>
      <p>{`I helped with the micro-animations for a teammates project.`}</p>
      <VideoPlayer linkHash="amK0yAGDNGI" mdxType="VideoPlayer" />
      <VideoPlayer linkHash="wfAerNESef4" mdxType="VideoPlayer" />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      